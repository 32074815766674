import {Settings} from '../Settings';

export default class ScrollStopElement {
		
	constructor(element) {
		this.element = $(element);
		this.section = this.element.parents('.section');
		this.elements = [];
		this.scrolling = false;
		this.current = 0;
		this.isLastSection = false;
		
		// create a dummy section below this section if it is the last one in order to preserve jquery.fullpage scrollEvents for this section
		// this elements scroll code will not be executed if jquery.fullpages onLeave event is not fired
		if(this.section.index() === $('.section').length - 1) {
			this.createDummySection();
			this.isLastSection = true;
		}
	}
	
	/**
	 * Check scroll requirements and set scrolling flag
	 * returns true if the element will scroll
	 */
	scroll(index, direction) {
		// index starts at 1, section.index at 0
		if(this.isActiveSection(index)) {
			
			if(
				// element is not currently scrolling
				this.scrolling === false &&
					// last element and down scroll
					((this.isLastElement() && direction === 'down') ||
					// first element and up scroll
					(this.isFirstElement() && direction === 'up')))
			{
				// element is finished, will not scroll
				return this.allowScroll(index, direction);
			}
				
			// element will scroll / is scrolling
			return this.cancelScroll();
		}
		// section is not active -> do not scroll
		return this.allowScroll(index, direction);
	}
	
	/**
	 * Allows scrolling so long as isLastSection is false
	 */
	allowScroll(index = 0, direction = '') {
		if(this.isLastSection && this.isActiveSection(index) && direction === 'down') {
			return true;
		}
		return false;
	}
	
	/**
	 * Cancels scrolling
	 */
	cancelScroll() {
		return true;
	}
	
	advanceCurrentCounter(direction) {
		if(
			(direction === 'down' && this.elements.length - 1 === this.current) || 
			(direction === 'up' && this.current === 0)) 
		{
			return;
		}
		
		this.current += this.directionAsNumber(direction);
	}
	
	/**
	 * Remove scrolling flag after scroll timeout
	 */
	scrollTimeout(timeout = Settings.scrollDuration) {
		window.setTimeout(() => {
			this.scrolling = false;
		}, timeout);
	}
	
	/**
	 * Get direction as numerical value
	 */
	directionAsNumber(direction) {	
		if(direction === 'up') {
			return -1;
		}
		return 1;
	}
	
	createDummySection() {
		this.section.after('<div class="section dummySection"></div>');
	}
	
	isActiveSection(index) {
		return this.section.index() + 1 === index;
	}
	
	isLastElement() {
		return this.current === this.elements.length - 1;
	}
	
	isFirstElement() {
		return this.current === 0;
	}
}