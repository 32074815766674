// parse integer values from typoscript settings
for(let property in IgFullpageSettings) {
	if(IgFullpageSettings.hasOwnProperty(property)) {
		let value = IgFullpageSettings[property];
		if(!isNaN(value)) {
			IgFullpageSettings[property] = parseInt(value);
		}
	}
}

// merge default settings with typoscript settings
let Settings = Object.assign({
	sectionSelector: '.section',
	normalScrollElements: '.normalScroll',
	navigationTooltips: IgFullpageNavTooltips,
	verticalCentered: true,
	scrollDuration: 700,
	lockAnchors: false,
	automaticSliderInterval: 5000
}, IgFullpageSettings);

export {Settings}