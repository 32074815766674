import {Settings} from '../Settings';
import ImageScroller from '../Elements/ImageScroller';
import TeaserRow from '../Elements/TeaserRow';

export default class fullpageController {

	constructor() {
		this.$window = $(window);
		// the page container
		this.container = $('#fullpage');
		// the header element
		this.header = $('header');
		// array of sections
		this.sections = null;
		this.currentSection = 0;
		// fpNav container
		this.fpNav = null;
		// fpNav li elements
		this.fpNavItems = null;
		// array of elements that will stop normal scroll progression until they are completed
		this.scrollStopElements = [];
		// whether the current scroll event was fired by a navClick
		this.navClick = false;
		// handle for the automatic gallery slider timeout
		this.gallerySliderInterval = null;
		this.automaticSlide = false;

		// initialise ScrollStopElements here!
		this.initImageScrollers();
		this.initTeaserRows();

		// initialise Page
		this.initFullpage();
	}

	/**
	 * Initialise the fullpage Plugin
	 */
	initFullpage() {
		// register fullpage callbacks here
		// return inner functions so they can stop the scrolling if necessary
		Settings.onLeave = (index, nextIndex, direction) => {
			return this.handleOnLeave(index, nextIndex, direction)
		};
		Settings.onSlideLeave = (anchorLink, index, slideIndex, direction, nextSlideIndex) => {
			return this.handleOnSlideLeave(anchorLink, index, slideIndex, direction, nextSlideIndex)
		};
		Settings.afterRender = () => {
			this.afterRender()
		};
		Settings.afterLoad = () => {
			this.afterLoad()
		};
		Settings.afterResize = () => {
			this.afterResize()
		};

		this.$window.on('resize', () => {
			this.centerSlideContent();
		});

		this.$window.on('temp.click temp.wheel temp.mousewheel temp.DOMMouseScroll', () => {
			$('body').addClass('desktop');
			this.$window.off('temp.click temp.wheel temp.mousewheel temp.DOMMouseScroll');
		});

		$('.gallerySlidePlay').on('click', () => {
			let section = this.getSection(this.currentSection);
			section.data('automaticSlidingCanceled', false);

			this.moveToNextSlide(section, this.currentSection);
			this.startGallerySlider(this.currentSection, true);
		});

		$('#fullpage').fullpage(Settings);
	}

	/**
	 * Initialise ImageScroller ScrollStopElements
	 */
	initImageScrollers() {
		$('.inImageScroller').each((index, element) => {
			this.scrollStopElements.push(new ImageScroller(element));
		});
	}

	/**
	 * Initialise TeaserRow ScrollStopElements
	 */
	initTeaserRows() {
		$('.teaserRow').each((index, element) => {
			this.scrollStopElements.push(new TeaserRow(element));
		});
	}

	/**
	 * Handle the fullpage onLeave Event
	 */
	handleOnLeave(index, nextIndex, direction) {
		this.currentSection = nextIndex;

		let section = this.getSection(nextIndex);

		this.toggleHeaderScrolled(nextIndex);

		if (!this.navClick) {
			// cancel move if scrollStopElement on current section is not finished and this scroll was not triggered via a anchor link
			for (let scrollStopElement of this.scrollStopElements) {
				if (scrollStopElement.scroll(index, direction)) {
					return false;
				}
			}
		}

		this.fpNavItems.find('a').removeClass('cActive');
		this.setActiveSideMenuItem(nextIndex);
		this.flashControlElements(section);

		this.startGallerySlider(nextIndex);
	}

	handleOnSlideLeave(anchorLink, index, slideIndex, direction, nextSlideIndex) {
		let section = this.getSection(index);

		section.data('currentSlide', nextSlideIndex);

		if(this.automaticSlide === false) {
			this.stopGallerySlider();
			section.data('automaticSlidingCanceled', true);
		}

		this.flashControlElements(section, nextSlideIndex);
	}

	/**
	 * Handle the fullpage afterRender Event
	 */
	afterRender() {
		this.sections = this.container.find('.section');
		this.sections.each((index, element) => {
			let $el = $(element);
			if($el.find('.fp-slides')) {
				$el.data('automaticSlidingCanceled', false);
				$el.data('slides', $el.find('.fp-slide').length);
				$el.data('currentSlide', 0);
			}
		});

		this.fpNav = $('#fp-nav');
		this.fpNavItems = this.fpNav.find('li');

		this.handleNavClick();
		this.hideSideMenuEntries();
		Foundation.reInit($('.row[data-equalizer]'));

		$('body').addClass('loaded');
		$('body').removeClass('nonSupportedDevice');
	}

	afterLoad() {
		this.centerSlideContent();
	}

	/**
	 * Browser resize event handler
	 */
	afterResize() {
		this.positionSideMenu();
	}

	/**
	 * Sets the navClick flag while a section scroll through an anchor link is played
	 */
	handleNavClick() {
		$('a[href*="#"]').on('click', (e) => {
			this.navClick = true;
			window.setTimeout(() => {
				this.navClick = false;
			}, Settings.scrollDuration);
		});
	}

	/**
	 * Hide sections in side menu if that option was selected in BE
	 * or the section is a dummy section created by a scrollStop Element
	 */
	hideSideMenuEntries() {
		this.sections.each((index, element) => {
			let $el = $(element);

			if ($el.data('notinsidemenu') === 1 || $el.hasClass('dummySection')) {
				this.fpNavItems.eq(index).hide();
			}
		});

		this.positionSideMenu();
	}

	/**
	 * Center the Side Menu on the page
	 */
	positionSideMenu() {
		this.fpNav.css('margin-top', this.fpNav.height() / -2);
	}

	/**
	 * find previous section that should appear in side menu and set the active class
	 * @param {int} index
	 * @returns {IgFullpageController@call;setActiveSideMenuItem}
	 */
	setActiveSideMenuItem(index) {
		if (index < 0) {
			return false;
		}

		if (this.getSection(index).data('notinsidemenu') === 1) {
			return this.setActiveSideMenuItem(--index);
		}

		this.getFpNavItem(index).find('a').addClass('cActive');
	}

	/**
	 * Add "sliding" class to fp-controlArrows while a slide is sliding
	 */
	flashControlElements(section, slideIndex = 0) {
		let elements = section.find('.fp-controlArrow, .slideTooltipTrigger, .gallerySlidePlay');

		elements.addClass('sliding');

		let tooltipTrigger = section.find('.slideTooltipTrigger'),
			galleryPlayButton = section.find('.gallerySlidePlay'),
			tooltipText = section.find('.slide').eq(slideIndex).find('.slideTooltipInner').html();

		tooltipTrigger.toggleClass('visible', (tooltipText !== undefined && tooltipText.length > 0));
		galleryPlayButton.toggleClass('visible', (section.data('automaticSlidingCanceled')) && window.outerWidth > Settings.responsiveWidth);

		window.setTimeout(() => {
			elements.removeClass('sliding')
		}, Settings.scrollDuration);
	}

	startGallerySlider(index, resetAutomaticSlideCanceling = false) {
		// stop previous interval
		this.stopGallerySlider();

		let section = this.getSection(index);

		if(resetAutomaticSlideCanceling) {
			section.data('automaticSlidingCanceled', false);
		}

		if(!section.data('automaticSlidingCanceled') && section.find('.fp-slidesContainer').length > 0) {
			this.gallerySliderInterval = window.setInterval(() => {
				this.moveToNextSlide(section, index);
			}, Settings.automaticSliderInterval);
		}
	}

	stopGallerySlider() {
		if(this.gallerySliderInterval !== null) {
			clearInterval(this.gallerySliderInterval);
			this.gallerySliderInterval = null;
		}
	}

	moveToNextSlide(section, index) {
		this.automaticSlide = true;

		if(section.data('currentSlide') === section.data('slides') - 1) {
			$.fn.fullpage.moveTo(index, 0);
		} else {
			$.fn.fullpage.moveSlideRight();
		}

		this.automaticSlide = false;
	}

	centerSlideContent() {
		$('.bg-slide .slideContent').each((index, element) => {
			let content = $(element),
				slide = content.parents('.fp-slide'),
				image = slide.find('.slide-background'),
				tooltipWrapper = slide.find('.slideTooltipWrapper'),
				tooltipPadding = parseInt($('.slideTooltipTrigger').first().css('padding-top')) / 2,
				imageOffset = (slide.parent().height() - slide.height()) / 2,
				contentOffset = (slide.parent().height() - content.height()) / 2;

			image.css('top',
				imageOffset
			);

			content.css('top',
				contentOffset
			);

			tooltipWrapper.css('bottom',
				Math.abs(imageOffset) * 2 - tooltipPadding
			);
		});
	}

	/**
	 * Toggle the scrolled header
	 */
	toggleHeaderScrolled(nextIndex) {
		if (nextIndex > 1) {
			$('header').addClass('scrolled');
		} else {
			$('header').removeClass('scrolled');
		}
	}

	getSection(index) {
		return this.sections.eq(index - 1);
	}

	getFpNavItem(index) {
		return this.fpNavItems.eq(index - 1);
	}
}