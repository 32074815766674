(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _Settings = require('../Settings');

var _ImageScroller = require('../Elements/ImageScroller');

var _ImageScroller2 = _interopRequireDefault(_ImageScroller);

var _TeaserRow = require('../Elements/TeaserRow');

var _TeaserRow2 = _interopRequireDefault(_TeaserRow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var fullpageController = function () {
	function fullpageController() {
		_classCallCheck(this, fullpageController);

		this.$window = $(window);
		// the page container
		this.container = $('#fullpage');
		// the header element
		this.header = $('header');
		// array of sections
		this.sections = null;
		this.currentSection = 0;
		// fpNav container
		this.fpNav = null;
		// fpNav li elements
		this.fpNavItems = null;
		// array of elements that will stop normal scroll progression until they are completed
		this.scrollStopElements = [];
		// whether the current scroll event was fired by a navClick
		this.navClick = false;
		// handle for the automatic gallery slider timeout
		this.gallerySliderInterval = null;
		this.automaticSlide = false;

		// initialise ScrollStopElements here!
		this.initImageScrollers();
		this.initTeaserRows();

		// initialise Page
		this.initFullpage();
	}

	/**
  * Initialise the fullpage Plugin
  */


	_createClass(fullpageController, [{
		key: 'initFullpage',
		value: function initFullpage() {
			var _this = this;

			// register fullpage callbacks here
			// return inner functions so they can stop the scrolling if necessary
			_Settings.Settings.onLeave = function (index, nextIndex, direction) {
				return _this.handleOnLeave(index, nextIndex, direction);
			};
			_Settings.Settings.onSlideLeave = function (anchorLink, index, slideIndex, direction, nextSlideIndex) {
				return _this.handleOnSlideLeave(anchorLink, index, slideIndex, direction, nextSlideIndex);
			};
			_Settings.Settings.afterRender = function () {
				_this.afterRender();
			};
			_Settings.Settings.afterLoad = function () {
				_this.afterLoad();
			};
			_Settings.Settings.afterResize = function () {
				_this.afterResize();
			};

			this.$window.on('resize', function () {
				_this.centerSlideContent();
			});

			this.$window.on('temp.click temp.wheel temp.mousewheel temp.DOMMouseScroll', function () {
				$('body').addClass('desktop');
				_this.$window.off('temp.click temp.wheel temp.mousewheel temp.DOMMouseScroll');
			});

			$('.gallerySlidePlay').on('click', function () {
				var section = _this.getSection(_this.currentSection);
				section.data('automaticSlidingCanceled', false);

				_this.moveToNextSlide(section, _this.currentSection);
				_this.startGallerySlider(_this.currentSection, true);
			});

			$('#fullpage').fullpage(_Settings.Settings);
		}

		/**
   * Initialise ImageScroller ScrollStopElements
   */

	}, {
		key: 'initImageScrollers',
		value: function initImageScrollers() {
			var _this2 = this;

			$('.inImageScroller').each(function (index, element) {
				_this2.scrollStopElements.push(new _ImageScroller2.default(element));
			});
		}

		/**
   * Initialise TeaserRow ScrollStopElements
   */

	}, {
		key: 'initTeaserRows',
		value: function initTeaserRows() {
			var _this3 = this;

			$('.teaserRow').each(function (index, element) {
				_this3.scrollStopElements.push(new _TeaserRow2.default(element));
			});
		}

		/**
   * Handle the fullpage onLeave Event
   */

	}, {
		key: 'handleOnLeave',
		value: function handleOnLeave(index, nextIndex, direction) {
			this.currentSection = nextIndex;

			var section = this.getSection(nextIndex);

			this.toggleHeaderScrolled(nextIndex);

			if (!this.navClick) {
				// cancel move if scrollStopElement on current section is not finished and this scroll was not triggered via a anchor link
				var _iteratorNormalCompletion = true;
				var _didIteratorError = false;
				var _iteratorError = undefined;

				try {
					for (var _iterator = this.scrollStopElements[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
						var scrollStopElement = _step.value;

						if (scrollStopElement.scroll(index, direction)) {
							return false;
						}
					}
				} catch (err) {
					_didIteratorError = true;
					_iteratorError = err;
				} finally {
					try {
						if (!_iteratorNormalCompletion && _iterator.return) {
							_iterator.return();
						}
					} finally {
						if (_didIteratorError) {
							throw _iteratorError;
						}
					}
				}
			}

			this.fpNavItems.find('a').removeClass('cActive');
			this.setActiveSideMenuItem(nextIndex);
			this.flashControlElements(section);

			this.startGallerySlider(nextIndex);
		}
	}, {
		key: 'handleOnSlideLeave',
		value: function handleOnSlideLeave(anchorLink, index, slideIndex, direction, nextSlideIndex) {
			var section = this.getSection(index);

			section.data('currentSlide', nextSlideIndex);

			if (this.automaticSlide === false) {
				this.stopGallerySlider();
				section.data('automaticSlidingCanceled', true);
			}

			this.flashControlElements(section, nextSlideIndex);
		}

		/**
   * Handle the fullpage afterRender Event
   */

	}, {
		key: 'afterRender',
		value: function afterRender() {
			this.sections = this.container.find('.section');
			this.sections.each(function (index, element) {
				var $el = $(element);
				if ($el.find('.fp-slides')) {
					$el.data('automaticSlidingCanceled', false);
					$el.data('slides', $el.find('.fp-slide').length);
					$el.data('currentSlide', 0);
				}
			});

			this.fpNav = $('#fp-nav');
			this.fpNavItems = this.fpNav.find('li');

			this.handleNavClick();
			this.hideSideMenuEntries();
			Foundation.reInit($('.row[data-equalizer]'));

			$('body').addClass('loaded');
			$('body').removeClass('nonSupportedDevice');
		}
	}, {
		key: 'afterLoad',
		value: function afterLoad() {
			this.centerSlideContent();
		}

		/**
   * Browser resize event handler
   */

	}, {
		key: 'afterResize',
		value: function afterResize() {
			this.positionSideMenu();
		}

		/**
   * Sets the navClick flag while a section scroll through an anchor link is played
   */

	}, {
		key: 'handleNavClick',
		value: function handleNavClick() {
			var _this4 = this;

			$('a[href*="#"]').on('click', function (e) {
				_this4.navClick = true;
				window.setTimeout(function () {
					_this4.navClick = false;
				}, _Settings.Settings.scrollDuration);
			});
		}

		/**
   * Hide sections in side menu if that option was selected in BE
   * or the section is a dummy section created by a scrollStop Element
   */

	}, {
		key: 'hideSideMenuEntries',
		value: function hideSideMenuEntries() {
			var _this5 = this;

			this.sections.each(function (index, element) {
				var $el = $(element);

				if ($el.data('notinsidemenu') === 1 || $el.hasClass('dummySection')) {
					_this5.fpNavItems.eq(index).hide();
				}
			});

			this.positionSideMenu();
		}

		/**
   * Center the Side Menu on the page
   */

	}, {
		key: 'positionSideMenu',
		value: function positionSideMenu() {
			this.fpNav.css('margin-top', this.fpNav.height() / -2);
		}

		/**
   * find previous section that should appear in side menu and set the active class
   * @param {int} index
   * @returns {IgFullpageController@call;setActiveSideMenuItem}
   */

	}, {
		key: 'setActiveSideMenuItem',
		value: function setActiveSideMenuItem(index) {
			if (index < 0) {
				return false;
			}

			if (this.getSection(index).data('notinsidemenu') === 1) {
				return this.setActiveSideMenuItem(--index);
			}

			this.getFpNavItem(index).find('a').addClass('cActive');
		}

		/**
   * Add "sliding" class to fp-controlArrows while a slide is sliding
   */

	}, {
		key: 'flashControlElements',
		value: function flashControlElements(section) {
			var slideIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

			var elements = section.find('.fp-controlArrow, .slideTooltipTrigger, .gallerySlidePlay');

			elements.addClass('sliding');

			var tooltipTrigger = section.find('.slideTooltipTrigger'),
			    galleryPlayButton = section.find('.gallerySlidePlay'),
			    tooltipText = section.find('.slide').eq(slideIndex).find('.slideTooltipInner').html();

			tooltipTrigger.toggleClass('visible', tooltipText !== undefined && tooltipText.length > 0);
			galleryPlayButton.toggleClass('visible', section.data('automaticSlidingCanceled') && window.outerWidth > _Settings.Settings.responsiveWidth);

			window.setTimeout(function () {
				elements.removeClass('sliding');
			}, _Settings.Settings.scrollDuration);
		}
	}, {
		key: 'startGallerySlider',
		value: function startGallerySlider(index) {
			var _this6 = this;

			var resetAutomaticSlideCanceling = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

			// stop previous interval
			this.stopGallerySlider();

			var section = this.getSection(index);

			if (resetAutomaticSlideCanceling) {
				section.data('automaticSlidingCanceled', false);
			}

			if (!section.data('automaticSlidingCanceled') && section.find('.fp-slidesContainer').length > 0) {
				this.gallerySliderInterval = window.setInterval(function () {
					_this6.moveToNextSlide(section, index);
				}, _Settings.Settings.automaticSliderInterval);
			}
		}
	}, {
		key: 'stopGallerySlider',
		value: function stopGallerySlider() {
			if (this.gallerySliderInterval !== null) {
				clearInterval(this.gallerySliderInterval);
				this.gallerySliderInterval = null;
			}
		}
	}, {
		key: 'moveToNextSlide',
		value: function moveToNextSlide(section, index) {
			this.automaticSlide = true;

			if (section.data('currentSlide') === section.data('slides') - 1) {
				$.fn.fullpage.moveTo(index, 0);
			} else {
				$.fn.fullpage.moveSlideRight();
			}

			this.automaticSlide = false;
		}
	}, {
		key: 'centerSlideContent',
		value: function centerSlideContent() {
			$('.bg-slide .slideContent').each(function (index, element) {
				var content = $(element),
				    slide = content.parents('.fp-slide'),
				    image = slide.find('.slide-background'),
				    tooltipWrapper = slide.find('.slideTooltipWrapper'),
				    tooltipPadding = parseInt($('.slideTooltipTrigger').first().css('padding-top')) / 2,
				    imageOffset = (slide.parent().height() - slide.height()) / 2,
				    contentOffset = (slide.parent().height() - content.height()) / 2;

				image.css('top', imageOffset);

				content.css('top', contentOffset);

				tooltipWrapper.css('bottom', Math.abs(imageOffset) * 2 - tooltipPadding);
			});
		}

		/**
   * Toggle the scrolled header
   */

	}, {
		key: 'toggleHeaderScrolled',
		value: function toggleHeaderScrolled(nextIndex) {
			if (nextIndex > 1) {
				$('header').addClass('scrolled');
			} else {
				$('header').removeClass('scrolled');
			}
		}
	}, {
		key: 'getSection',
		value: function getSection(index) {
			return this.sections.eq(index - 1);
		}
	}, {
		key: 'getFpNavItem',
		value: function getFpNavItem(index) {
			return this.fpNavItems.eq(index - 1);
		}
	}]);

	return fullpageController;
}();

exports.default = fullpageController;

},{"../Elements/ImageScroller":2,"../Elements/TeaserRow":4,"../Settings":5}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _Settings = require('../Settings');

var _ScrollStopElement2 = require('./ScrollStopElement');

var _ScrollStopElement3 = _interopRequireDefault(_ScrollStopElement2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Image Scroller that stops fullpage scroll until all images are scrolled through
 */
var ImageScroller = function (_ScrollStopElement) {
	_inherits(ImageScroller, _ScrollStopElement);

	function ImageScroller(element) {
		_classCallCheck(this, ImageScroller);

		var _this = _possibleConstructorReturn(this, (ImageScroller.__proto__ || Object.getPrototypeOf(ImageScroller)).call(this, element));

		_this.innerImages = _this.element.find('.innerImages');
		_this.elements = _this.innerImages.find('img');

		_this.element.on('click', function (e) {
			_this.handleClick(e);
		});
		return _this;
	}

	_createClass(ImageScroller, [{
		key: 'scroll',
		value: function scroll(index, direction) {
			if (!_get(ImageScroller.prototype.__proto__ || Object.getPrototypeOf(ImageScroller.prototype), 'scroll', this).call(this, index, direction)) {
				// element will not scroll -> allow page scroll
				return this.allowScroll(index, direction);
			}

			this.scrollToNextElement(direction);

			// do not allow page scroll while scrolling is in progress
			return this.cancelScroll();
		}
	}, {
		key: 'scrollToNextElement',
		value: function scrollToNextElement(direction) {
			if (this.scrolling === false && window.outerWidth >= _Settings.Settings.responsiveWidth) {
				this.scrolling = true;

				this.advanceCurrentCounter(direction);
				this.scrollTimeout();
				this.updateImageScroller();
			}
		}
	}, {
		key: 'updateImageScroller',
		value: function updateImageScroller() {
			var top = 0;

			for (var i = 0; i < this.current; i++) {
				top -= this.elements.eq(i).outerHeight(true);
			}

			this.innerImages.css('transform', 'translateY(' + top + 'px)');
		}
	}, {
		key: 'handleClick',
		value: function handleClick(e) {
			this.scrollToNextElement('down');
		}
	}]);

	return ImageScroller;
}(_ScrollStopElement3.default);

exports.default = ImageScroller;

},{"../Settings":5,"./ScrollStopElement":3}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _Settings = require('../Settings');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ScrollStopElement = function () {
	function ScrollStopElement(element) {
		_classCallCheck(this, ScrollStopElement);

		this.element = $(element);
		this.section = this.element.parents('.section');
		this.elements = [];
		this.scrolling = false;
		this.current = 0;
		this.isLastSection = false;

		// create a dummy section below this section if it is the last one in order to preserve jquery.fullpage scrollEvents for this section
		// this elements scroll code will not be executed if jquery.fullpages onLeave event is not fired
		if (this.section.index() === $('.section').length - 1) {
			this.createDummySection();
			this.isLastSection = true;
		}
	}

	/**
  * Check scroll requirements and set scrolling flag
  * returns true if the element will scroll
  */


	_createClass(ScrollStopElement, [{
		key: 'scroll',
		value: function scroll(index, direction) {
			// index starts at 1, section.index at 0
			if (this.isActiveSection(index)) {

				if (
				// element is not currently scrolling
				this.scrolling === false && (
				// last element and down scroll
				this.isLastElement() && direction === 'down' ||
				// first element and up scroll
				this.isFirstElement() && direction === 'up')) {
					// element is finished, will not scroll
					return this.allowScroll(index, direction);
				}

				// element will scroll / is scrolling
				return this.cancelScroll();
			}
			// section is not active -> do not scroll
			return this.allowScroll(index, direction);
		}

		/**
   * Allows scrolling so long as isLastSection is false
   */

	}, {
		key: 'allowScroll',
		value: function allowScroll() {
			var index = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
			var direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

			if (this.isLastSection && this.isActiveSection(index) && direction === 'down') {
				return true;
			}
			return false;
		}

		/**
   * Cancels scrolling
   */

	}, {
		key: 'cancelScroll',
		value: function cancelScroll() {
			return true;
		}
	}, {
		key: 'advanceCurrentCounter',
		value: function advanceCurrentCounter(direction) {
			if (direction === 'down' && this.elements.length - 1 === this.current || direction === 'up' && this.current === 0) {
				return;
			}

			this.current += this.directionAsNumber(direction);
		}

		/**
   * Remove scrolling flag after scroll timeout
   */

	}, {
		key: 'scrollTimeout',
		value: function scrollTimeout() {
			var _this = this;

			var timeout = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _Settings.Settings.scrollDuration;

			window.setTimeout(function () {
				_this.scrolling = false;
			}, timeout);
		}

		/**
   * Get direction as numerical value
   */

	}, {
		key: 'directionAsNumber',
		value: function directionAsNumber(direction) {
			if (direction === 'up') {
				return -1;
			}
			return 1;
		}
	}, {
		key: 'createDummySection',
		value: function createDummySection() {
			this.section.after('<div class="section dummySection"></div>');
		}
	}, {
		key: 'isActiveSection',
		value: function isActiveSection(index) {
			return this.section.index() + 1 === index;
		}
	}, {
		key: 'isLastElement',
		value: function isLastElement() {
			return this.current === this.elements.length - 1;
		}
	}, {
		key: 'isFirstElement',
		value: function isFirstElement() {
			return this.current === 0;
		}
	}]);

	return ScrollStopElement;
}();

exports.default = ScrollStopElement;

},{"../Settings":5}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _ScrollStopElement2 = require('./ScrollStopElement');

var _ScrollStopElement3 = _interopRequireDefault(_ScrollStopElement2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

/**
 * Define Scroll behaviour for teaser rows
 */
var TeaserRow = function (_ScrollStopElement) {
	_inherits(TeaserRow, _ScrollStopElement);

	function TeaserRow(element) {
		_classCallCheck(this, TeaserRow);

		var _this = _possibleConstructorReturn(this, (TeaserRow.__proto__ || Object.getPrototypeOf(TeaserRow)).call(this, element));

		_this.scrollDuration = 500;

		_this.elements = _this.element.find('.teaser');
		_this.updateTeasers();

		_this.elements.on('click', function (e) {
			_this.handleClick(e);
		});
		return _this;
	}

	_createClass(TeaserRow, [{
		key: 'scroll',
		value: function scroll(index, direction) {
			if (!_get(TeaserRow.prototype.__proto__ || Object.getPrototypeOf(TeaserRow.prototype), 'scroll', this).call(this, index, direction)) {
				// element will not scroll -> allow page scroll
				return this.allowScroll(index, direction);
			}

			if (this.scrolling === false) {
				this.scrolling = true;

				this.advanceCurrentCounter(direction);
				this.scrollTimeout(this.scrollDuration);
				this.updateTeasers();
			}

			// do not allow page scroll while scrolling is in progress
			return this.cancelScroll();
		}
	}, {
		key: 'updateTeasers',
		value: function updateTeasers() {
			this.elements.removeClass('act');

			this.elements.eq(this.current).addClass('act activeTeaser');
		}
	}, {
		key: 'handleClick',
		value: function handleClick(e) {
			var targetIndex = $(e.delegateTarget).parent().index();

			if (this.current !== targetIndex) {
				this.scrolling = true;

				this.current = targetIndex;
				this.scrollTimeout(this.scrollDuration);
				this.updateTeasers();
			}
		}
	}]);

	return TeaserRow;
}(_ScrollStopElement3.default);

exports.default = TeaserRow;

},{"./ScrollStopElement":3}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
// parse integer values from typoscript settings
for (var property in IgFullpageSettings) {
	if (IgFullpageSettings.hasOwnProperty(property)) {
		var value = IgFullpageSettings[property];
		if (!isNaN(value)) {
			IgFullpageSettings[property] = parseInt(value);
		}
	}
}

// merge default settings with typoscript settings
var Settings = Object.assign({
	sectionSelector: '.section',
	normalScrollElements: '.normalScroll',
	navigationTooltips: IgFullpageNavTooltips,
	verticalCentered: true,
	scrollDuration: 700,
	lockAnchors: false,
	automaticSliderInterval: 5000
}, IgFullpageSettings);

exports.Settings = Settings;

},{}],6:[function(require,module,exports){
'use strict';

var _FullpageController = require('./Controller/FullpageController');

var _FullpageController2 = _interopRequireDefault(_FullpageController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Controller = new _FullpageController2.default();

},{"./Controller/FullpageController":1}]},{},[6])

