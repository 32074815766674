import {Settings} from '../Settings';
import ScrollStopElement from './ScrollStopElement';

/**
 * Image Scroller that stops fullpage scroll until all images are scrolled through
 */
export default class ImageScroller extends ScrollStopElement {

	constructor(element) {
		super(element);

		this.innerImages = this.element.find('.innerImages');
		this.elements = this.innerImages.find('img');
		
		this.element.on('click', (e) => {this.handleClick(e)});
	}

	scroll(index, direction) {		
		if(!super.scroll(index, direction))	{
			// element will not scroll -> allow page scroll
			return this.allowScroll(index, direction);
		}
		
		this.scrollToNextElement(direction);

		// do not allow page scroll while scrolling is in progress
		return this.cancelScroll();
	}
	
	scrollToNextElement(direction) {
		if(this.scrolling === false && window.outerWidth >= Settings.responsiveWidth) {
			this.scrolling = true;

			this.advanceCurrentCounter(direction);
			this.scrollTimeout();
			this.updateImageScroller();
		}
	}
	
	updateImageScroller() {
		let top = 0;
		
		for(let i = 0; i < this.current; i++) {
			top -= this.elements.eq(i).outerHeight(true);
		}
		
		this.innerImages.css('transform', `translateY(${top}px)`);
	}
	
	handleClick(e) {
		this.scrollToNextElement('down');
	}
}