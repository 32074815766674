import ScrollStopElement from './ScrollStopElement';

/**
 * Define Scroll behaviour for teaser rows
 */
export default class TeaserRow extends ScrollStopElement {

	constructor(element) {
		super(element);
		
		this.scrollDuration = 500;

		this.elements = this.element.find('.teaser');
		this.updateTeasers();
		
		this.elements.on('click', (e) => {this.handleClick(e)});
	}

	scroll(index, direction) {
		if(!super.scroll(index, direction))	{
			// element will not scroll -> allow page scroll
			return this.allowScroll(index, direction);
		}
		
		if(this.scrolling === false) {
			this.scrolling = true;
		
			this.advanceCurrentCounter(direction);
			this.scrollTimeout(this.scrollDuration);
			this.updateTeasers();
		}
			
		// do not allow page scroll while scrolling is in progress
		return this.cancelScroll();
	}

	updateTeasers() {
		this.elements.removeClass('act');
		
		this.elements.eq(this.current).addClass('act activeTeaser');
	}
	
	handleClick(e) {
		let targetIndex = $(e.delegateTarget).parent().index();
		
		if(this.current !== targetIndex) {
			this.scrolling = true;
			
			this.current = targetIndex;
			this.scrollTimeout(this.scrollDuration);
			this.updateTeasers();
		}
	}
}